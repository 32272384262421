export const experienceData = [
    {
        id: 1,
        company: 'Tirios Inc',
        jobtitle: 'Senior Machine Learning Engineer',
        startYear: 'April 2023',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'TERAKOYA Academia Inc Pvt. Ltd.',
        jobtitle: 'Software Engineer (AI/ML)',
        startYear: 'August 2022',
        endYear: 'June 2023'
    },
    {
        id: 3,
        company: 'Logictronix Technologies Pvt. Ltd',
        jobtitle: 'Machine Learning Engineer',
        startYear: 'May 2021',
        endYear: 'July 2022'
    },
    {
        id: 4,
        company: 'Eydean Inc Pvt. Ltd',
        jobtitle: 'Jr. Artificial Intelligence Developer',
        startYear: 'Sepetember 2019',
        endYear: 'April 2021'
    },
]