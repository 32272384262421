export const contactsData = {
    email: 'monicaprjpt@gmail.com',
    phone: '(+977) 9861445494',
    address: 'Kalanki-14, Kathmandu, Nepal',
    github: 'https://github.com/monika-prajapati',
    linkedIn: 'https://www.linkedin.com/in/monika-prajapati-0a4b3a146/',
    // twitter: 'https://twitter.com/said7388',
    // stackOverflow: 'https://stackoverflow.com/users/16840768/abu-said',
    devUsername: 'monikaprajapati_70',
    sheetAPI: 'https://sheetdb.io/api/v1/ldzvwk1e465c5'
}