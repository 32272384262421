/* eslint-disable */
import { FaTabletAlt, FaImages } from "react-icons/fa";
import { BsFileEarmarkZipFill, BsTextareaT } from "react-icons/bs";
import { GiSewingString, GiArchiveResearch, GiArtificialIntelligence } from "react-icons/gi";
import { FaRobot } from "react-icons/fa6";
import { VscGraphLine } from "react-icons/vsc";
import { SlSpeech } from "react-icons/sl";
import { TbApi } from "react-icons/tb";


export const servicesData = [
    {
        id: 1,
        title: 'Custom Models',
        icon: <GiArtificialIntelligence />
    },
    {
        id: 2,
        title: 'RAG Models',
        icon: <BsFileEarmarkZipFill />
    },
    {
        id: 3,
        title: 'Fine-tune Models',
        icon: <GiSewingString />
    },
    {
        id: 4,
        title: 'Implement Research Papers',
        icon: <GiArchiveResearch />
    },
    {
        id: 5,
        title: 'GPTs',
        icon: <FaRobot />
    },

    {
        id: 6,
        title: 'Data Analysis',
        icon: <VscGraphLine />
    },
    {
        id: 7,
        title: 'Text-to-Text',
        icon: <BsTextareaT />
    },
    {
        id: 8,
        title: 'Speech-to-Text',
        icon: <SlSpeech />
    },
    {
        id: 9,
        title: 'Image-to-Text',
        icon: <FaImages />
    },
    {
        id: 10,
        title: 'RESTful API',
        icon: <TbApi />
    },
    {
        id: 11,
        title: 'MLOPs',
        icon: <FaTabletAlt />
    }

]
