import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { HiArrowRight } from "react-icons/hi";

import './Blog.css';
import { ThemeContext } from '../../contexts/ThemeContext';
// import { blogData } from '../../data/blogData'
// import SingleBlog from './SingleBlog/SingleBlog';
import BlogCard from './BlogCard/blog-card'
import UseBlog from '../../hooks/Blog/useBlog';

function Blog() {

    const { blogs: blogData } = UseBlog()
    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
        viewAllBtn: {
            color: theme.tertiary,
            backgroundColor: theme.primary,
            "&:hover": {
                color: theme.secondary,
                backgroundColor: theme.primary,
            }
        },
        viewArr: {
            color: theme.tertiary,
            backgroundColor: theme.secondary70,
            width: '40px',
            height: '40px',
            padding: '0.5rem',
            fontSize: '1.05rem',
            borderRadius: '50%',
            cursor: 'pointer',
            "&:hover": {
                color: theme.tertiary,
                backgroundColor: theme.secondary,
            }
        },
    }));

    const classes = useStyles();


    return (
        <>
            {blogData.length > 0 && (
                <div className="blog" id="blog" style={{ backgroundColor: theme.primary }}>
                    <div className="blog--header">
                        <h1 style={{ color: theme.secondary }}>Blog</h1>
                    </div>
                    <div className="blog--body">
                        <div className="blog--bodyContainer">
                            {blogData.slice(0, 3).reverse().map(blog => (
                                <BlogCard
                                    theme={theme}
                                    blog={blog}
                                    key={blog.id}
                                />
                            ))}
                        </div>

                        {blogData.length > 3 && (
                            <div className="blog--viewAll">
                                <a href="https://blog.monikaprajapati.com.np">
                                    <button className={classes.viewAllBtn}>
                                        View All
                                        <HiArrowRight className={classes.viewArr} />
                                    </button>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    )
}

export default Blog
