export const educationData = [
    {
        id: 1,
        institution: 'Tribhuwan University',
        course: 'Bachelor Degree in Computer Science and Information Technology',
        startYear: '2016',
        endYear: '2021'
    },
    {
        id: 2,
        institution: 'National Abode for Scholars and Academicians Higher Secondary School',
        course: 'Higher Secondary School',
        startYear: '2014',
        endYear: '1016'
    },
]